import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import "../styles/style.css"
import profileImg from '../images/nathaniel.png'

export default function Home() {
  return (
    <>
      <Helmet>
          <title>Nathaniel Emodi</title>
      </Helmet>
      <main>
        <header>
        <nav>
          <ul>
            <li>
              <Link to="/">About</Link>
            </li>
            <li>
              <Link to="/reading-list">Reading List</Link>
            </li>
            <li>
              <Link to="/investing">Investing</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </nav>
        </header>
        <section className="post">
          <h2>Nathaniel Emodi</h2>
          
          <p>I’m building a <Link to="http://www.highlight.xyz">new company</Link> in Web3. Previously I helped lead the Caviar business at Square and DoorDash. I also invest in early stage startups and advise growth stage tech companies. </p>
          <p>I'm passionate about developing scalable businesses around new ideas. My experience is in product design, growth strategy, business development, data-driven decision making, and leading and developing high-performing teams.</p>
          <p>
          I grew up in Canada and studied at McGill University.
          </p>
          <p>You can reach me on <Link to="http://www.twitter.com/natemodi" target={"_blank"}>Twitter</Link> or <Link to="https://warpcast.com/emodi" target={"_blank"}>Farcaster</Link>.</p>
        </section>
      </main>
    </>
  )
}
